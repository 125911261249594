import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { useScrollToTop } from "../hooks/useScrollToTop";
const ScrollToTopButton = () => {
  const {
    shown,
    scrollToTop
  } = useScrollToTop(300);
  return <button aria-label="scroll to top" onClick={scrollToTop} className={`${shown ? "scale-100" : "scale-0"} fixed bottom-10 right-4 z-50 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-sm shadow-gray-900 transition-transform duration-200 sm:right-10 sm:h-12 sm:w-12`}>
      <ArrowUpIcon className="h-5 w-5" />
    </button>;
};
export default ScrollToTopButton;